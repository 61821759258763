import async from 'async';
import moment from 'moment';
import numeral from 'numeral';
import store from '../../store';
import Types from '../../store/Types';

import * as network from '../../network';

export {
    uploadFile, uploadError, uploadToAWS, publicUploadToAWS, awsUpload, updateVue,
};

/** UPLOAD TO AWS + SYNCFILE UPLOAD
 * @param {Object} file
 * @param {Object} user
 * @param {Function} callback
 */
function uploadFile(file, user, callback) {
    const { localFile, syncFile } = file;
    const { internalName } = syncFile;

    const { schoolId, schoolTermId } = user.school;
    async.auto({
        parameters(next) {
            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    fileType: 'data-uploads',
                },
                body: {
                    fileParameters: {
                        fileName: localFile.name,
                    },
                },
            };

            network.storage.getAWSFormDetails(params, (err, formDetails) => {
                if (err) return next(err);
                next(null, formDetails);
            });
        },
        aws: ['parameters', function (results, next) {
            awsUpload(results.parameters, localFile, syncFile, next);
        }],
        syncFileUploads: ['aws', function (results, next) {
            const bucketPath = results.parameters.fields.key;
            const fileSize = numeral(localFile.size).format('0.0 b');

            const fileLastModified = moment(new Date(localFile.lastModified)).format('llll');

            const params = {
                url: { schoolId, schoolTermId, internalName },
                body: {
                    bucketPath,
                    fileSize,
                    fileLastModified,
                },
            };
            network.storage.saveSyncFileDetails(params, next);
        }],
    }, (err, results) => {
        if (err) return callback(err);
        callback(err, results);
    });
}

/**
 * @param {Object} localFile
 * @param {Object} user
 * @param {Function} callback
 */
function uploadError(localFile, user, callback) {
    const { schoolId, schoolTermId } = user.school;
    async.auto({
        parameters(next) {
            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    fileType: 'error-bin',
                },
                body: {
                    fileParameters: {
                        fileName: localFile.name,
                    },
                },
            };
            network.storage.getAWSFormDetails(params, (err, res) => {
                if (err) return next(err);
                next(null, res);
            });
        },
        aws: ['parameters', function (results, next) {
            awsUpload(results.parameters, localFile, null, next);
        }],
        saveError: ['aws', function (results, next) {
            const bucketPath = results.parameters.fields.key;
            const fileSize = numeral(localFile.size).format('0.0 b');
            const fileName = localFile.name;
            const fileLastModified = moment(new Date(localFile.lastModified)).format('llll');

            const params = {
                url: { schoolId, schoolTermId },
                body: {
                    fileName,
                    bucketPath,
                    fileSize,
                    fileLastModified,
                    fileError: localFile.err ? localFile.err : 'Unknown file',
                },
            };
            network.storage.saveSyncFileError(params, (err, res) => {
                if (err) return next(err);
                next(err, res.file);
            });
        }],
    }, (err, results) => {
        if (err) return callback(err);
        callback(err, results.saveError);
    });
}

/** ONLY UPLOAD TO AWS BUCKET AND RETURN BUCKET PATH
 * @param {Object} file
 * @param {Object} user
 * @param {Function} callback
 */
function uploadToAWS(fileType, file, user, callback) {
    const { schoolId, schoolTermId } = user.school;
    async.auto({
        parameters(next) {
            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    fileType,
                },
                body: {
                    fileParameters: {
                        fileName: file.name,
                    },
                },
            };

            network.storage.getAWSFormDetails(params, (err, formDetails) => {
                if (err) return next(err);
                next(null, formDetails);
            });
        },
        aws: ['parameters', function (results, next) {
            awsUpload(results.parameters, file, false, next);
        }],
    }, (err, results) => {
        if (err) return callback(err);
        callback(err, results);
    });
}

/** PUBLIC SECURE UPLOAD TO AWS BUCKET AND RETURN BUCKET PATH
 * @param {Object} file
 * @param {Function} callback
 */
function publicUploadToAWS(params, file, callback) {
    const { name, email, schoolDBN, uploadId } = params;

    async.auto({
        parameters(next) {
            const params = {
                body: {
                    fileParameters: {
                        name,
                        email,
                        schoolDBN,
                        uploadId,
                        fileType: 'public-secure-uploads',
                        fileName: file.name,
                    },
                },
            };

            network.landing.getAWSFormDetails(params, (err, formDetails) => {
                if (err) return next(err);
                next(null, formDetails);
            });
        },
        aws: ['parameters', (results, next) => {
            awsUpload(results.parameters, file, false, next);
        }],
        expiryLink: ['aws', (results, next) => {
            const bucketPath = results.parameters.fields.key;
            network.landing.getAWSExpiryLink({ body: { bucketPath } }, next);
        }],
    }, (err, results) => {
        if (err) return callback(err);
        callback(err, results);
    });
}

/**
 * @param {Object} awsParameters
 * @param {Object} localFile
 * @param {Object} syncFile
 * @param {Function} callback
 */
function awsUpload(awsParameters, localFile, syncFile, callback) {
    const formData = new FormData();
    Object.keys(awsParameters.fields).forEach((name) => {
        formData.append(name, awsParameters.fields[name]);
    });

    const fileProgress = {
        status: 'Preparing',
        percentComplete: 0,
        stringPercentage: '0%',
        error: null,
    };

    if (syncFile) updateVue(fileProgress, localFile, syncFile);

    formData.append('file', localFile);
    // presentation.bucketPath = awsParameters.fields.key;

    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener('progress', (evt) => {
        if (evt.lengthComputable) {
            fileProgress.status = 'Uploading';
            fileProgress.percentComplete = Math.round((evt.loaded * 100) / evt.total);
            fileProgress.stringPercentage = (`${fileProgress.percentComplete.toString()}%`);
            if (syncFile) updateVue(fileProgress, localFile, syncFile);
        }
    }, false);

    xhr.addEventListener('load', (evt) => {
        if (evt.target.status >= 200 && evt.target.status < 300) {
            fileProgress.error = null;
            fileProgress.status = 'Uploaded';
            fileProgress.percentComplete = 100;
            fileProgress.stringPercentage = '100%';
        } else {
            fileProgress.status = 'Error';
            fileProgress.error = 'Upload failed';
            fileProgress.stringPercentage = '0%';
        }
        if (syncFile) updateVue(fileProgress, localFile, syncFile);
        return callback();
    }, false);

    xhr.addEventListener('error', () => {
        fileProgress.status = 'Error';
        fileProgress.error = 'Upload failed';
        fileProgress.stringPercentage = '0%';
        if (syncFile) updateVue(fileProgress, localFile, syncFile);
        return callback();
    }, false);

    xhr.addEventListener('abort', () => {
        fileProgress.status = 'Error';
        fileProgress.error = 'Upload failed';
        fileProgress.stringPercentage = '0%';
        if (syncFile) updateVue(fileProgress, localFile, syncFile);
        return callback();
    }, false);

    const { bucket } = awsParameters.fields;
    xhr.open('POST', `https://s3.amazonaws.com/${bucket}`, true);
    xhr.send(formData);
}

function updateVue(filePresentation, localFile, syncFile) {
    if (!syncFile) return;
    store.commit(Types.mutations.EDIT_DATA_SYSTEM_SYNC_FILE, { filePresentation, localFile, syncFile });
}
